import logo from './assets/vividlogix_logo.png';
import './App.css';

function App() {
  return (
    <div class="container mx-auto">
      <div class="my-5 py-5"><img src={logo} alt="vividlogix logo" class="mx-auto"/> </div>
      <div class="my-5 mx-auto text-3xl text-center py-5">Coming Soon</div>
    </div>

    
  );
}

export default App;
